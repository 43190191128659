<template>

<!--    <div class="toggle" @click="isFiltersVisible = !isFiltersVisible">-->
<!--      <span>Filters</span>-->
<!--      <span :class="{ rotated: isFiltersVisible }">▼</span>-->
<!--    </div>-->
<!--    <div class="filters" :class="{ open: isFiltersVisible }">-->
      <div class="filter-row">
        <ClientDataFilter @update:filter="updateFilter" />
        <FilterAgent v-if="showFilterAgent" :agents="agents" @update:filter="updateFilter" />
        <ClientStatusFilter v-if="showFilterActivity" @update:filter="updateFilter" />
        <ClientSourceFilter v-if="showFilterClientSource" @update:filter="updateFilter" />
        <FilterCreationDate v-if="showFilterCreationDate" @update:filter="updateFilter" />
      </div>
      <div class="filter-row">
        <DepartureCityFilter v-if="showFilterDeparture" @update:filter="updateFilter" />
        <DestinationCityFilter v-if="showFilterDestination" @update:filter="updateFilter" />
        <DepartureDateFilter v-if="showFilterDepartureDate" @update:filter="updateFilter" />
        <ReturnDateFilter v-if="showFilterReturnDate" @update:filter="updateFilter" />
        <PassengerTypeFilter  v-if="showFilterPassengerType" @update:filter="updateFilter" />
      </div>

      <div class="filter-row">
        <PQSentCountFilter v-if="showFilterPQsSentCount" @update:filter="updateFilter" />
        <FollowupEmailsFilter v-if="showFilterFollowUpCount" @update:filter="updateFilter" />
        <OpenedEmailsFilter v-if="showFilterEmailOpenedCount" @update:filter="updateFilter" />
        <OAFOpenedFilter v-if="showFilterOafOpenedCount" @update:filter="updateFilter" />
        <v-btn color="blue" class="mt-7" @click="applyFilters" :loading="isLoading" :disabled="isLoading">Apply Filters
        <v-overlay absolute v-if="isLoading">
          <v-progress-circular indeterminate size="24"></v-progress-circular>
        </v-overlay>
        </v-btn>
        <v-btn color="blue darken-1" class="mt-7" v-if="filtersApplied" @click="resetFilters">Reset</v-btn>
      </div>

<!--    </div>-->
  </template>

  <script>
    import { reactive , ref } from "vue";
    import { router } from '@inertiajs/vue3';
    import FilterAgent from "./FilterAgent.vue";
    import FilterCreationDate from "./FilterCreationDate.vue";
    import DepartureCityFilter from "./DepartureCityFilter.vue";
    import DestinationCityFilter from "./DestinationCityFilter.vue";
    import ClientDataFilter from "./ClientDataFilter.vue";
    import DepartureDateFilter from "./DepartureDateFilter.vue";
    import ReturnDateFilter from "./ReturnDateFilter.vue";
    import PQSentCountFilter from "./PQSentCountFilter.vue";
    import FollowupEmailsFilter from "./FollowupEmailsFilter.vue";
    import ClientStatusFilter from "./ClientStatusFilter.vue";
    import {useBackendMiddlewarePropsService} from "@services";
    import OpenedEmailsFilter from "./OpenedEmailsFilter.vue";
    import ClientSourceFilter from "./ClientSourceFilter.vue";
    import OAFOpenedFilter from "./OAFOpenedFilter.vue";
    import PassengerTypeFilter from "./PassengerTypeFilter.vue";

    export default {
    components: {
      FilterCreationDate,
      FilterAgent,
      DepartureCityFilter,
      DestinationCityFilter,
      ClientDataFilter,
      DepartureDateFilter,
      ReturnDateFilter,
      PQSentCountFilter,
      FollowupEmailsFilter,
      ClientStatusFilter,
      OpenedEmailsFilter,
      ClientSourceFilter,
      OAFOpenedFilter,
      PassengerTypeFilter
    },
    props: {
      agents: Array,
    },
    emits: ["filters-updated"], // Declare emit event
    setup(_, { emit }) {
      const filters = reactive({}); // Store collected filter values
      const activeFilters = ref({});
      const isLoading = ref(false);
      const filtersApplied = ref(false)
      const isFiltersVisible = ref(false);
      const {backendPermissions} = useBackendMiddlewarePropsService()
      const showFilterAgent = backendPermissions.allowFilterByAgentName
      const showFilterCreationDate = backendPermissions.allowFilterByCreationDate
      const showFilterDeparture = backendPermissions.allowFilterByDeparture
      const showFilterDestination = backendPermissions.allowFilterByDestination
      const showFilterDepartureDate = backendPermissions.allowFilterByDepartureDate
      const showFilterReturnDate = backendPermissions.allowFilterByReturnDate
      const showFilterPassengerType = backendPermissions.allowFilterByPassengerType
      const showFilterActivity = backendPermissions.allowFilterByActivity
      const showFilterPQsSentCount = backendPermissions.allowFilterByPQsSentCount
      const showFilterFollowUpCount = backendPermissions.allowFilterByFollowUpCount
      const showFilterEmailOpenedCount = backendPermissions.allowFilterByEmailOpenedCount
      const showFilterOafOpenedCount = backendPermissions.allowFilterByOafOpenedCount
      const showFilterClientSource = backendPermissions.allowFilterByClientSource

      // Collect filters from child components
      const updateFilter = ({ key, value }) => {
        filters[key] = value;
      };

      // Function to build request with only active filters
      const getActiveFilters = () => {
          return Object.fromEntries(
              Object.entries(filters).filter(([_, value]) => value !== null && value !== undefined) // Keep only non-empty filters
          );
      };

      // Emit the filters to the parent component
      const applyFilters = () => {
        isLoading.value = true;
        activeFilters.value = getActiveFilters()
        router.get('requests', {
          filters: activeFilters.value
        }, {
          preserveState: true,
          onSuccess: () => {
            isLoading.value = false;
            filtersApplied.value = true;
          }
        },
      )

      };

      // Reset filters
      const resetFilters = () => {
        Object.keys(filters).forEach(key => (filters[key] = ""));
        filtersApplied.value = false;
        try {
          router.get('/requests')
        } catch (error) {
            console.error('Error:', error);
        }
      };

      return {
        isLoading,
        updateFilter,
        applyFilters,
        resetFilters,
        filtersApplied,
        isFiltersVisible,
        activeFilters,
        filters,
        showFilterAgent,
        showFilterCreationDate,
        showFilterDeparture,
        showFilterDestination,
        showFilterDepartureDate,
        showFilterReturnDate,
        showFilterPassengerType,
        showFilterActivity,
        showFilterPQsSentCount,
        showFilterFollowUpCount,
        showFilterEmailOpenedCount,
        showFilterOafOpenedCount,
        showFilterClientSource
      };
    }
  };
</script>

<style scoped>
  .filter-row {
    display: flex;
    gap: 15px;
  }

  .toggle {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    font-weight: bold;
    user-select: none;
    margin-bottom: 15px;
  }

  .toggle span:last-child {
    transition: transform 0.3s ease-in-out;
  }

  .toggle .rotated {
    transform: rotate(180deg);
  }

  .filters {
    transition: opacity 0.3s ease-in-out;
  }

  .filters.open {
    max-height: 300px; /* Adjust as needed */
    padding: 10px;
  }
</style>
